/* eslint-disable import/no-mutable-exports */
let envVar = process.env.REACT_APP_ENV;
// if (!env)
//   if (typeof window !== 'undefined') {
//     switch (window.location.host) {
//       case 'wcaculture-admin.iscriptsdemo.com':
//         env = 'staging';
//         break;
//       case 'manage.wecareaboutculture.com':
//         env = 'production';
//         break;
//       default:
//         env = 'development';
//     }
//   } else {
//     env = 'development';
//   }
let envValues = {};
switch (envVar) {
  case 'staging':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'https://wcaculture-api.iscriptsdemo.com/v1',
      REACT_APP_API_FRONTEND_ENDPOINT: 'https://wcaculture-api.iscriptsdemo.com/api',
      REACT_APP_API_FRONT_END: 'https://wcaculture.iscriptsdemo.com',
      REACT_APP_API_PERMISSION_DENIED: 'https://wcaculture-admin.iscriptsdemo.com/permission',
      GOOGLE_MAP_API_KEY: 'AIzaSyDsONmhB1UdObaWni9Dbc1_tqhsyp7hZUc',
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: 'ON',
      COLLECTIONURL: 'notifications',
    };
    break;
  case 'production':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'https://api.wecareaboutculture.com/v1',
      REACT_APP_API_FRONTEND_ENDPOINT: 'https://api.wecareaboutculture.com/api',
      REACT_APP_API_FRONT_END: 'https://wecareaboutculture.com',
      REACT_APP_API_PERMISSION_DENIED: 'https://manage.wecareaboutculture.com/permission',
      GOOGLE_MAP_API_KEY: 'AIzaSyDsONmhB1UdObaWni9Dbc1_tqhsyp7hZUc',
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: 'OFF',
      COLLECTIONURL: 'notifications',
    };
    break;
  case 'development':
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'http://localhost:4075/v1',
      REACT_APP_API_FRONTEND_ENDPOINT: 'https://wcaculture-api.iscriptsdemo.com/api',
      REACT_APP_API_FRONT_END: 'http://localhost:3000',
      REACT_APP_API_PERMISSION_DENIED: 'http://localhost:3000/permission',
      GOOGLE_MAP_API_KEY: 'AIzaSyDsONmhB1UdObaWni9Dbc1_tqhsyp7hZUc',
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: 'OFF',
      COLLECTIONURL: 'notifications',
    };
    break;
  default:
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: 'http://localhost:4075/v1',
      REACT_APP_API_FRONTEND_ENDPOINT: 'https://wcaculture-api.iscriptsdemo.com/api',
      REACT_APP_API_FRONT_END: 'https://wcaculture.iscriptsdemo.com',
      REACT_APP_API_PERMISSION_DENIED: 'https://wcaculture-admin.iscriptsdemo.com/permission',
      GOOGLE_MAP_API_KEY: 'AIzaSyDsONmhB1UdObaWni9Dbc1_tqhsyp7hZUc',
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: 'OFF',
      COLLECTIONURL: 'notifications',
    };
    break;
}

export default envValues;
